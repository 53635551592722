import loggeduser from '@/views/loggeduser';
import onboarding from '@/views/onboarding';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    ...onboarding.routes(),
    ...loggeduser.routes()
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition){
        window.scrollTo({ 
            left: savedPosition ? savedPosition.left : 0, 
            top: savedPosition ? savedPosition.top : 0,
            behavior: <any>'instant'
        });
    }
})

export default router
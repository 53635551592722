import { SaveFileVM, fileName, getWeightedVolumeIntensity } from "./AudioRecorderServices";

//WEB
let audioCtxWeb : AudioContext;
let analyserWeb : AnalyserNode;
let data : Uint8Array;
let mediaRecorder: any;
let mediaStream: MediaStream;
let chunks: Blob[] = [];
let audioTypeWeb = "";
let millisecondInterval = 60;
let interval;

let intervalDuration;
let duration = 0;

export function requestWeb(): Promise<void>{
    let prom = new Promise<void>( (resolve, reject) => {
        if (!audioCtxWeb || audioCtxWeb.state === 'closed') {
            initAudioWeb();
        }

        navigator.mediaDevices.getUserMedia({audio: true, video: false})
        .then( stream => {
            mediaStream = stream;
            const source = audioCtxWeb.createMediaStreamSource(stream);

            source.connect(analyserWeb); // Collega la sorgente all'AnalyserNode
            // analyserWeb.connect(audioCtxWeb.destination); //uscita audio
            mediaRecorder = new MediaRecorder(stream);
            resolve();
        }).catch( err => {
            reject(err);
        })
    })

    return prom;
}

export function startWeb( onStop: (saveFileVM: SaveFileVM) => void, onError : (error: string) => void, onVolumeIntensityAdded: (intensity: number) => void,
    onDurationUpdated: (duration: number) => void, maxValue: number){
    duration = 0;

    onDurationUpdated(duration);
    chunks = [];
    
    mediaRecorder.ondataavailable = (e)=>{
        audioTypeWeb = e.data.type;

        chunks.push(e.data);
    }

    //function to catch error
    mediaRecorder.onerror = (e)=>{
        onError(e.error);
    }

    mediaRecorder.onstop = (e)=>{
        clearInterval(intervalDuration);
        let blob = new Blob(chunks,  { type: audioTypeWeb });
        //create url for audio
        let url = URL.createObjectURL(blob);

        var file = new File([blob], fileName());

        var saveFileVM: SaveFileVM = {
            url: url,
            file: file,
        };
        //pass url into audio tag
        onStop(saveFileVM);
    }

    mediaRecorder.start();

    intervalDuration = setInterval(() => {
        duration += 1;
        onDurationUpdated(duration);
    }, 1000);

    interval = setInterval(() => {
        var average = getWeightedVolumeIntensity(analyserWeb, data, maxValue)
        onVolumeIntensityAdded(average);
    }, millisecondInterval)
}

export function stopWeb(){
    mediaRecorder.stop();
    audioCtxWeb.close();
    mediaStream.getTracks().forEach(track => track.stop());
    clearInterval(interval);
}

function initAudioWeb(){
    audioCtxWeb = new (window.AudioContext || window.webkitAudioContext)();
    analyserWeb = audioCtxWeb.createAnalyser();
    // Impostazione delle proprietà dell'AnalyserNode
    analyserWeb.fftSize = 2048; // Imposta la dimensione della FFT (deve essere una potenza di 2)
    data = new Uint8Array(analyserWeb.frequencyBinCount);
}

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class imageAndQuestionsViewOtherProfile extends Vue {

    @Prop() profileQuestion: OM.NewMatchQuestion;

    getAffinityAnswer(index: number){
        var answersPercentage = this.profileQuestion.answersPercentage;

        if(!answersPercentage || answersPercentage.length == 0)
            return "";

        return answersPercentage[index];
    }
    
    isBomb(index: number){
        var answersPercentage = this.profileQuestion.answersPercentage;

        if(!answersPercentage || answersPercentage.length == 0 || !this.profileQuestion.minPercentage)
            return false;

        return answersPercentage[index] == 25;
    }

    getButtonClass(index: number){
        var classes = ""

        if(this.profileQuestion.answerIndex != null && this.profileQuestion.answerIndex != -1)
            classes += "preview "

        if(this.profileQuestion.answerIndex == null || this.profileQuestion.answerIndex != index)
            return classes + "box_shadow";

        return classes + "blue";
    }

}

import { debugAnalytics, platformList } from '@/config';
import { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router';
import * as firebase from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import store from '@/store';
import { getMessaging } from "firebase/messaging";
import * as FirebaseOM from './FirebaseOM';

//Per il debug di analytics
    //DOCUMENTAZIONE
    //https://firebase.google.com/docs/analytics/debugview
    //PAGINA DI DEBUG SU FIREBASE
    //https://console.firebase.google.com/project/_/analytics/debugview
    //WEB
    //To enable Analytics debug mode in your browser, install the Google Analytics Debugger Chrome extension.
    // Once installed, enable the extension and refresh the page. From that point on, the extension will log events in your app in debug mode.
    // You can view events logged in the DebugView in the Firebase console.
    //ANDROID
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG (SOSTITUIRE PACKAGE_NAME)
    //adb shell setprop debug.firebase.analytics.app package_name     
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO
    //adb shell setprop debug.firebase.analytics.app .none.
    //IOS
    //ABILITARE IL PROPRIO DISPOSITIVO IN MODALITA' DEBUG DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    //-FIRDebugEnabled 
    //DISABILITARE LA MODALITA' DEBUG SUL PROPRIO DISPOSITIVO DA INSERIRE NEGLI ARGOMENTI DELLA RIGA DI COMANDO DI XCODE
    // -FIRDebugDisabled
//-------------------------------------------------------

//Nuova guida analytics
// https://github.com/gtm-support/vue-gtm#sync-gtm-with-your-router

//Bisogna abilitare e creare i google tag manager account
//https://tagmanager.google.com/?authuser=1#/home

//common settings
var authDomain = "feven-sagl.firebaseapp.com";
var projectId = "feven-sagl";
var storageBucket = "feven-sagl.appspot.com";
var messagingSenderId = "623573398821";

//Per analytics web
var GA_MEASUREMENT_ID = "G-DD9RP8QF45"; 
var webApiKey = "AIzaSyAY-tFMUecxCIhvHenjcXd8pS7sS1KTyrs";
var webAppId = "1:623573398821:web:927c5a9dfbad2e2cde56cc";

//Per analytics android
var androidApiKey = "AIzaSyA0RbvOKnYLe-0tj2Qqxb_X6jq5By95khY";
var androidAppId = "1:623573398821:android:c7b4e15a89f1989ade56cc";

//Per analytics ios
var iosApiKey = "AIzaSyAtNilx-lDdosRXnyTaJipqR1xS5pVWc6U";
var iosAppId = "1:623573398821:ios:4407e896e196d4fbde56cc";

//configurazione firebase
const configWeb = {
    apiKey: webApiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: webAppId,
    measurementId: GA_MEASUREMENT_ID
};

// const configAndroid = {
//     apiKey: androidApiKey,
//     authDomain: authDomain,
//     projectId: projectId,
//     storageBucket: storageBucket,
//     messagingSenderId: messagingSenderId,
//     appId: androidAppId,
//     measurementId: undefined
// };

// const configIos = {
//     apiKey: iosApiKey,
//     authDomain: authDomain,
//     projectId: projectId,
//     storageBucket: storageBucket,
//     messagingSenderId: messagingSenderId,
//     appId: iosAppId,
//     measurementId: undefined
// };

//Init firebase
//Chiamare al deviceready
//L'inizializzazione serve per l'analytics WEB e APP
export function initFirebase(){
    if(window.cordova)
        return;

    var config = configWeb;
    
    // if(window.cordova && window.cordova.platformId == platformList.android)
    //     config = configAndroid;
    // else if(window.cordova && window.cordova.platformId == platformList.ios)
    //     config = configIos;

    firebase.initializeApp(config);
}

//track page view or modal
export function trackPageView(page: RouteLocationNormalized) {
    if(debugAnalytics)
        return;

    var userAgent = window.navigator.userAgent;
    var pageEncoding = "UTF-8";
    
    var object : FirebaseOM.FirebaseTrackPageView = {
        page_location: page.fullPath,
        page_path: page.fullPath,
        page_title: page.name.toString(),
        language: store.state.loggedUser.preferredCulture,
        page_encoding: pageEncoding,
        user_agent: userAgent
   };

   if(!window.cordova)
        trackPageViewWeb(object);
    else if(window.cordova && store.state.deviceReady)
        trackPageViewApp(object)
}

function trackPageViewWeb(object: FirebaseOM.FirebaseTrackPageView){
    var apps = firebase.getApps();
    if(apps.length == 0)
        return;

    var firebaseApp = apps[0];
    var analyticsApp = getAnalytics(); 
    logEvent(analyticsApp, 'page_view', object);
}

function trackPageViewApp(object: FirebaseOM.FirebaseTrackPageView){
    FirebasePlugin.setScreenName(object.page_title);
    FirebasePlugin.logEvent("page_view", object);
}

//send custom event
// https://firebase.google.com/docs/analytics/events?platform=web
export function trackEvent(event: string, object: any) {
    if(debugAnalytics)
        return;

    if(!window.cordova)
        trackEventWeb(event, object);
    else if(window.cordova && store.state.deviceReady)
        trackEventApp(event, object);
}

function trackEventWeb(event: string, object: any){
    var apps = firebase.getApps();
    if(apps.length == 0)
        return;

    var firebaseApp = apps[0];

    var analyticsApp = getAnalytics(); 
    logEvent(analyticsApp, event, object);
}

function trackEventApp(event: string, object: any){
    FirebasePlugin.logEvent(event, object);
}

//https://developers.google.com/tag-platform/gtagjs/reference/events
export const CustomEventNameList = {
    add_payment_info : "add_payment_info",
    add_shipping_info: "add_shipping_info",
    add_to_cart: "add_to_cart",
    add_to_wishlist: "add_to_wishlist",
    begin_checkout: "begin_checkout",
    earn_virtual_currency: "earn_virtual_currency",
    exception: "exception",
    generate_lead: "generate_lead",
    join_group: "join_group",
    level_end: "level_end",
    level_start: "level_start",
    level_up: "level_up",
    login: "login",
    post_score: "post_score",
    purchase: "purchase",
    refund: "refund",
    remove_from_cart: "remove_from_cart",
    search: "search",
    select_content: "select_content",
    select_promotion: "select_promotion",
    share: "share",
    sign_up: "sign_up",
    spend_virtual_currency: "spend_virtual_currency",
    tutorial_begin: "tutorial_begin",
    tutorial_complete: "tutorial_complete",
    unlock_achievement: "unlock_achievement",
    view_cart: "view_cart",
    view_item: "view_item",
    view_item_list: "view_item_list",
    view_promotion: "view_promotion",
    view_search_results: "view_search_results"
}

import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({
    components: {
    }
})
export default class GeolocationTimeOutModal extends Vue {

    @Prop() confirmCb: () => void;
    @Prop() denyCb: () => void;

    created(){
    }

    mounted(){
    }

}

import { StorageServices } from '@/services/StorageServices';
import { CommonServices } from './CommonServices';
import { AppUserClient, LocalizationClient } from './Services';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Vue, VueConstructor } from 'vue-class-component';
import InfoModal from '@/components/modals/infoModal.vue';
import GeolocationTimeOutModal from '@/components/modals/geolocationTimeOutModal.vue';
import { getViewWithServices } from '@/utils';

class _LocalizationServices {

    constructor() {}

    SetLanguage(language: string) {
        var loggedUser = StorageServices.getLoggedUser();
        if(loggedUser.identifier) {
            AppUserClient.changeLanguageAndGetToken(language, false)
            .then( x => {
                loggedUser.preferredCulture = language;
                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
                CommonServices.setAuthToken(x);
            }).catch( err => {
                loggedUser.preferredCulture = language;
                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
            })
        }
        else {
            loggedUser.preferredCulture = language;
            StorageServices.setLoggedUser(loggedUser);
            store.state.loggedUser = loggedUser;

            store.state.showSpinner++;
            location.reload();
        }
    }
    
    GetLanguage() {
        return store.state.selectedLanguage;
    }
    
    getLocalizedValue(key: string, value: string, afterText: string = '', prevText: string = '', object: any = null): string {
        if(store.state.consts.localizedValues.length == 0){
            var result = prevText + value + afterText;
            return this.replaceText(result, object);
        }

        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        if (existing) {
            var selectedCulture = store.state.loggedUser.preferredCulture;

            var result = prevText + existing.values[selectedCulture] + afterText;
            return this.replaceText(result, object);
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            
            LocalizationClient.saveLocalizedValue(newValue, false)
                .then(x => {
                    var newLocalizedValue = new OM.LocalizedEntity();
                    newLocalizedValue.key = key;
                    newLocalizedValue.identifier = "";
                    store.state.consts.enabledLanguages.forEach(x => {
                        newLocalizedValue.values[x] = value;
                    })
                    store.state.consts.localizedValues.push(newLocalizedValue);
                })
            var result = prevText + value + afterText;
            return this.replaceText(result, object);
        }
    }

    getTextFromValues(value: { [key: string]: string; }, customLang: string = "") {
        var lang = StorageServices.getLoggedUser().preferredCulture;
        if (customLang)
            lang = customLang;

        var keys = Object.keys(value);
        
        if (keys.indexOf(lang) == -1 && keys.indexOf(VM.defaultLanguage) != -1)
            return value[VM.defaultLanguage];
        else if(keys.indexOf(lang) == -1)
            return ""

        return value[lang];
    }

    getTextFromLocalizedValue(model: OM.LocalizedValue, customLang: string = "") {
        var values = model.values;

        if (customLang)
            return this.getTextFromValues(values, customLang);

        return this.getTextFromValues(values);
    }

    getPrivacyPolicyLink() {
        return this.getTextFromLocalizedValue(store.state.consts.config.privacyPolicyUrl);
    }

    getTermsAndConditionsLink() {
        return this.getTextFromLocalizedValue(store.state.consts.config.termsConditionsUrl);
    }

    getEnabledLanguageList(afterText: string = '', prevText: string = ''): OM.TextIdentifier[] {

        if(!store.state.consts.enabledLanguages || store.state.consts.enabledLanguages.length == 0)
            return [];


        var result = store.state.consts.enabledLanguages.map( x => {
            var language = new OM.TextIdentifier();
            language.identifier = x;
            language.text = this.getLocalizedValue("app_language_" + x, x, afterText, prevText);

            return language;
        })

        return result;
    }

    positionOptions : PositionOptions = {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0
    }

    getCurrentLocation() : Promise<void>{
        var prom = new Promise<void>((resolve, reject) => {
            CommonServices.showSpinner();
            window.navigator.geolocation.getCurrentPosition(
                (result) => {
                    var currentLocation = StorageServices.getCurrentPosition();

                    if(!currentLocation)
                        currentLocation = new OM.Address();

                    currentLocation.latitude = result.coords.latitude;
                    currentLocation.longitude = result.coords.longitude;

                    StorageServices.setCurrentPosition(currentLocation);
                    store.state.currentPosition = currentLocation;
                    
                    resolve();
                    CommonServices.hideSpinner();
                },
                (error: GeolocationPositionError) => {
                    // console.log(error)
                    var errorCode = error ? error.code : GeolocationPositionError.POSITION_UNAVAILABLE;

                    CommonServices.hideSpinner();

                    if(errorCode == GeolocationPositionError.PERMISSION_DENIED){
                        this.geolocationPermissionDenied()
                        .then( x => {
                            resolve();
                        }).catch( () => {
                            reject();
                        });
                    } else if(errorCode == GeolocationPositionError.TIMEOUT && window.cordova && window.cordova.platformId === 'android'){
                        this.geolocationTimeout()
                        .then( x => {
                            resolve();
                        }).catch( () => {
                            reject();
                        });
                    } else {
                        this.geolocationPositionUnavailable()
                        .then( x => {
                            resolve();
                        }).catch( () => {
                            reject();
                        });
                    }
                }, 
                this.positionOptions
            );
        });

        return prom;
    }

    geolocationPermissionDenied(){
        var prom = new Promise<void>((resolve, reject) => {
            var viewWithServices = getViewWithServices();
            viewWithServices.$opModal.show(InfoModal, {
                img: 'pin_error.svg',
                text: viewWithServices.$localizationService.getLocalizedValue("app_FevenNecessitaDellAccessoAllaTuaPosizione", "Feven necessita dell'accesso alla tua posizione"),
                subtitle: viewWithServices.$localizationService.getLocalizedValue("app_VaiNelleImpostazioniDelTuoDispositivoEConcediLAccessoAllaTuaPosizioneAFeven", "Vai nelle impostazioni del tuo dispositivo e concedi l'accesso alla tua posizione a Feven"),
                confirm: viewWithServices.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                deny: viewWithServices.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    viewWithServices.$opModal.closeLast();
                    this.getCurrentLocation()
                    .then( x => {
                        resolve();
                    }).catch( () => {
                        reject();
                    });
                },
                denyCb: () => {
                    viewWithServices.$opModal.closeLast();
                    reject();
                }
            })
        })

        return prom;
    }

    geolocationPositionUnavailable(){
        var prom = new Promise<void>((resolve, reject) => {
            var viewWithServices = getViewWithServices();
            viewWithServices.$opModal.show(InfoModal, {
                img: 'pin_error.svg',
                text: viewWithServices.$localizationService.getLocalizedValue("app_FevenNecessitaDellAccessoAllaTuaPosizione", "Feven necessita dell'accesso alla tua posizione"),
                subtitle: viewWithServices.$localizationService.getLocalizedValue("app_SembraCheNonSiaPossibileRilevareLaTuaPosizioneEsatta. Controlla la connessione e riprova.", "Sembra che non sia possibile rilevare la tua posizione esatta. Controlla la connessione e riprova."),
                confirm: viewWithServices.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                deny: viewWithServices.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    viewWithServices.$opModal.closeLast();
                    this.getCurrentLocation()
                    .then( x => {
                        resolve();
                    }).catch( () => {
                        reject();
                    });
                },
                denyCb: () => {
                    viewWithServices.$opModal.closeLast();
                    reject();
                }
            })
        })

        return prom;
    }

    geolocationTimeout(){
        var prom = new Promise<void>((resolve, reject) => {
            var viewWithServices = getViewWithServices();

            viewWithServices.$opModal.show(GeolocationTimeOutModal, {
                confirmCb: () => {
                    viewWithServices.$opModal.closeLast();
                    this.getCurrentLocation()
                    .then( x => {
                        resolve();
                    }).catch( () => {
                        reject();
                    });
                },
                denyCb: () => {
                    viewWithServices.$opModal.closeLast();
                    reject();
                }
            })
        })

        return prom;
    }

    replaceText(text: string, object: any): string {
        if(!object)
            return text;
        
        while(text.indexOf('{{') != -1){
            var init = text.indexOf("{{");
            var end = text.indexOf("}}");
    
            if(init == -1 || end == -1)
                break;
            
            var textToReplace = text.substring(init, text.length - (text.length - end) + 2);
            var variableName = textToReplace.substring(2, textToReplace.length - 2);
            
            if(object[variableName] == null || object[variableName] == undefined){
                console.error("Variabile: " + variableName + " non esistente nell'oggetto");
                break;
            }
    
            text = text.replace(textToReplace, object[variableName]);
        }
    
        return text;
    }

    getMyPositionText(){
        var key = "app_LaMiaPosizione";
        var text = "La mia posizione";

        return this.getLocalizedValue(key, text);
    }
}

export let LocalizationServices = new _LocalizationServices();
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal bg_white insertTextModal" }
const _hoisted_2 = { class: "modal_header pt-4" }
const _hoisted_3 = { class: "page_padding pt-3" }
const _hoisted_4 = { class: "page_padding pt-0 modal_footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { white: false }, {
        right: _withCtx(() => [
          _createVNode("img", {
            src: _imports_0,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
          })
        ]),
        _: 1
      })
    ]),
    _createVNode("div", _hoisted_3, [
      (_ctx.titleKey && _ctx.titleValue)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            class: "fev_text2 fw_700 mb-3 text-center",
            localizedKey: _ctx.titleKey,
            text: _ctx.titleValue
          }, null, 8, ["localizedKey", "text"]))
        : _createCommentVNode("", true),
      _createVNode(_component_form_field, {
        type: _ctx.inputType,
        placeholderKey: _ctx.inputPlaceholderKey,
        placeholderValue: _ctx.inputPlaceholderValue,
        modelValue: _ctx.localValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.localValue = $event)),
        class: "darkInput"
      }, null, 8, ["type", "placeholderKey", "placeholderValue", "modelValue"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        class: "fev_button bg_brand",
        disabled: !_ctx.localValue,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: _ctx.buttonKey,
          text: _ctx.buttonText
        }, null, 8, ["localizedKey", "text"])
      ], 8, ["disabled"])
    ])
  ]))
}
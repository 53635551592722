export let baseUrl = process.env.VUE_APP_ENDPOINT;
export let siteUrl = process.env.VUE_APP_WEBSITE;

export let stripeKey = process.env.VUE_APP_STRIPE_KEY;

//custom variable set in package.json
export let debugAnalytics = process.env.VUE_APP_DEBUG.trim() == "true"; 

//purchase
export let purchaseIos = process.env.VUE_APP_PURCHASE_APPLE;
export let purchaseAndroid = process.env.VUE_APP_PURCHASE_ANDROID;

//smartlook
export let smartlookAPIKey = process.env.VUE_APP_SMARTLOOKKEY;

export const platformList = {
    web: "web",
    android: "android",
    ios: "ios"
}

//app version number (Presa on device ready)
let appVersionNumber = "";

export function setAppVersionNumber(version: string){
    appVersionNumber = version;
}

export function getAppVersionNumber(){
    return appVersionNumber;
}
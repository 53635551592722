
import { Prop } from "vue-property-decorator";
import { StripeClient } from "@/services/Services";
import { StorageServices } from "@/services/StorageServices";
import store from '@/store';
import * as OM from "@/Model";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';

@Options({})
export default class StripeSavedCards extends mixins(WithModel) {

    @Prop({
        default: () => []
    }) cards: OM.StripeCardDetailVm[];

	selectedCard: OM.StripeCardDetailVm = new OM.StripeCardDetailVm();
	loaded = false;

	created() {
        this.$nextTick( () => {
            this.loaded = true;
        })
	}

	selectCard(card: OM.StripeCardDetailVm) {
		this.emitModel(card.cardId);
    }
    
}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import bodymovin from 'lottie-web';

@Options({
    components: {
    }
})
export default class Affinitybar extends Vue {

    @Prop() isActive: boolean;

    mounted(){
        this.isActiveChanged();
    }

    step = 0;
    removedStep = 0;

    timeoutHandler;
    secondTimeoutHandler;
    @Watch("isActive")
    isActiveChanged(){
        if(!this.isActive){
            this.step = 0;
            this.removedStep = 0;
            clearInterval(this.timeoutHandler);
            clearInterval(this.secondTimeoutHandler);
            return;
        }
        
        setTimeout(() => {
            var animation = bodymovin.loadAnimation({
                container: <any>this.$refs.lottieAnimation,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/affinitybar.json'
            })

            animation.play();
            animation.loop = false;
        }, 0);
        

        this.step = 1;    
        this.playAnimation();
    }

    playAnimation(){
        this.timeoutHandler = setTimeout(() => {
            this.removedStep ++;

            setTimeout(() => {
                this.step ++;
                if(this.step < 4)
                    this.playAnimation();
            }, 850);
        }, 2000);
    }

}


import { clearNotificationAndCheckAnotherOne, isNewMesasgeNotification, notificationClicked, notificationTimerPause, notificationTimerResume } from '@/services/Firebase/FirebasePushServices';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import notification from '../views/loggeduser/components/notification.vue';

@Options({
    components: {
        notification
    }
})
export default class App extends Vue {
    
    hasDown = false; //il click o il touch è iniziato sulla notifica
    isMoving = false; //c'è stato un movimento dall'inizio del click, se non c'è stato chiamerà "onclick" al rilascio
    notificationRef = null; //html element preso tramite getbyid 

    timerAnimation = 500; //durata dell'animazione di entrata ed uscita della notifica

    maxY = 0; //il massimo di translateY applicabile (viene settato come getBoundingClientRect().bottom di notificationRef)
    startY = 0; //Y dell'inizio del click o del touch
    moveY = 0; //Y del mousemove o touchmove

    minTranslateY = 56; //il translateY di default della notifica (se c'è)
    diff = 56; //viene inizializzato come il minTranslateY poi viene usato in style nella notification come translateY

    minTranslateYForClose = 20; //il minimo di variazione per chiudere la notifica
    
    removingNotification = false; //evita di chiamare più volte l'eliminazione della notifica

    mounted(){
        if(window.cordova){
            document.addEventListener("touchmove", this.onTouchMove);
            document.addEventListener("touchend", this.onUp);
        } else {
            document.addEventListener("mousemove", this.onMouseMove);
            document.addEventListener("mouseup", this.onUp);
        }
    }
    
    @Watch("$store.state.notification")
    onNotificationChanged(){
        if(!store.state.notification){
            return;
        }

        setTimeout(() => {
            this.notificationRef = document.getElementById("notification_box");
            var boundingClientRect = this.notificationRef.getBoundingClientRect();
            this.maxY = boundingClientRect.bottom - 1;
        }, this.timerAnimation);
    }

    @Watch("removingNotification")
    changedRemovingNotification(){
        if(!this.removingNotification)
            return;

        setTimeout(() => {
            this.removingNotification = false;
        }, this.timerAnimation);
    }

    notificationClicked(){
        if(this.hasDown && this.isMoving)
            return;

        var isNewMessageNotification = isNewMesasgeNotification(store.state.notification);
        var sameNotification = store.state.notificationList.filter( x => store.state.notification.route && store.state.notification.route == x.route || store.state.notification.targetRoute && store.state.notification.targetRoute == x.targetRoute);
        var notificationNumber = sameNotification.length;

        var otherNotification = sameNotification.slice(1);
        otherNotification.forEach(element => {
            var index = store.state.notificationList.indexOf(element);
            store.state.notificationList.splice(index, 1);
        });

        if(isNewMessageNotification)
            store.state.affinityNotificationNumber -= notificationNumber;
        else
            store.state.notificationNumber -= notificationNumber;

        notificationClicked(this.$store.state.notification);
    }

    //Init interaction with notification
    //mouse
    onMouseDown(ev: MouseEvent){
        this.hasDown = true;
        this.startY = ev.clientY;
        notificationTimerPause();
    }

    onMouseMove(ev: MouseEvent){
        if(!this.hasDown)
            return;

        this.isMoving = true;
        this.moveY = ev.clientY;
       
        this.checkMove();
    }

    onUp(){
        if(!this.hasDown)
            return;

        notificationTimerResume();

        if(!this.isMoving){
            this.hasDown = false;
            return;
        }

        setTimeout(() => {
            this.diff = this.minTranslateY;
            this.hasDown = false;
            this.isMoving = false;
        }, 100);
    }

    //touch
    onTouchStart(ev: TouchEvent){
        this.hasDown = true;
        this.startY = ev.changedTouches[0].clientY;
        notificationTimerPause();
    }

    onTouchMove(ev: TouchEvent){
        if(!this.hasDown)
            return;

        this.isMoving = true;
        this.moveY = ev.changedTouches[0].clientY;

        this.checkMove();
    }

    checkMove(){
       if(this.moveY >= this.maxY){
            this.diff = this.minTranslateY;
            return;
        }

        var tempDiff = this.moveY - this.startY + this.minTranslateY;

        if(tempDiff > this.minTranslateY)
            return;

        if(this.removingNotification)
            return;
            
        if(tempDiff <= this.minTranslateYForClose){
            this.removingNotification = true;
            clearNotificationAndCheckAnotherOne()
            this.diff = this.minTranslateY;
            this.hasDown = false;
            return;
        }

        this.diff = tempDiff;
    }

}

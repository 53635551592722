import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/attention_white.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "reportQuestion",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.reportQuestion()), ["stop","prevent"]))
  }, [
    (_ctx.showAlertImage)
      ? (_openBlock(), _createBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
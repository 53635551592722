import { requestWeb, startWeb, stopWeb } from './AudioRecorderWebServices';
import { requestCordova, startCordova, stopCordova } from './AudioRecorderCordovaServices';
const fileNamePrefix = 'audio_recording';
const fileNameExt = '.mp3';

export function fileName(){
    return fileNamePrefix + Math.random().toString() + fileNameExt;
}

export class SaveFileVM {
    url: string;
    file: File | string;

    constructor(){

    }
}

export function request(): Promise<void>{

    if(window.cordova)
        return requestCordova();

    return requestWeb();
}

export function start(onStop: (saveFileVM: SaveFileVM) => void, onError : (error: string) => void, onVolumeIntensityAdded: (intensity: number) => void,
    onDurationUpdated: (duration: number) => void, maxValue: number){

    if(window.cordova)
        startCordova(onError, onVolumeIntensityAdded, onDurationUpdated, maxValue);
    else
        startWeb(onStop, onError, onVolumeIntensityAdded, onDurationUpdated, maxValue);
}

export function stop(onStop: (saveFileVM: SaveFileVM) => void,
    onError : (error: string) => void, maxValue: number){
    if(window.cordova)
        stopCordova(onStop, onError, maxValue);
    else
        stopWeb();
}

let treshold = 0;
export function getWeightedVolumeIntensity(analyserNode: AnalyserNode, frequencyData: Uint8Array, maxValue: number) {
    analyserNode.getByteFrequencyData(frequencyData);

    var tempFrequencyData = frequencyData.filter( x => x > treshold);
    // var tempFrequencyData = frequencyData;

    if(tempFrequencyData.length == 0){
        return 1;
    }
    
    // const sortedNumbers = tempFrequencyData.slice().sort((a, b) => b - a);

    // // Prendi i primi 30 numeri
    // const halfTop = sortedNumbers.slice(0, tempFrequencyData.length / 3);

    // Calcola la somma dei 30 numeri più alti
    const total = tempFrequencyData.reduce((acc, curr) => acc + curr, 0);

    // var total = tempFrequencyData.reduce( (tot, el) => tot + el, 0);
    var average = total / tempFrequencyData.length;

    var scaledAverage = (average / 255) * maxValue;
    
    if(scaledAverage < 1 || !scaledAverage){
        return 1;
    }

    return scaledAverage;
}

// export function calculateAverageAmplitude(analyserNode, maxValue) {
//     const dataArray = new Float32Array(analyserNode.fftSize);
//     analyserNode.getFloatTimeDomainData(dataArray);

//     let sum = 0;
//     for (let i = 0; i < dataArray.length; i++) {
//       sum += Math.abs(dataArray[i]); // Utilizziamo il valore assoluto per considerare sia le oscillazioni positive che quelle negative
//     }

//     var average = sum / dataArray.length;

//     var scaledAverage = average * maxValue;

//     return scaledAverage;
// }

// export function calculateAverageAmplitude(analyserNode, maxValue) {
//     const dataArray = new Float32Array(analyserNode.fftSize);
//     analyserNode.getFloatTimeDomainData(dataArray);

//     let sum = 0;
//     let count = 0;
//     for (let i = 0; i < dataArray.length; i++) {
//         if(dataArray[i] >= 0){
//             sum += Math.abs(dataArray[i]);
//             count ++;
//         }
//     }

//     var average = sum / count;
//     var scaledAverage = average * maxValue;

//     return scaledAverage;
// }

// export function amplitudeToDecibels(averageAmplitude, referenceAmplitude = 1) {
//     const ratio = averageAmplitude / referenceAmplitude;

//     return 20 * Math.log10(ratio);
// }

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import viewDescriptionModal from '@/views/loggeduser/modals/viewDescriptionModal.vue';
import * as OM from "@/Model";

@Options({})
export default class ViewProfile extends Vue {

    @Prop() match: OM.MatchDetailVm;
    @Prop() questions: OM.ProfileQuestion[];

    @Prop({
        default: ""
    }) affinity: string;

    @Prop({
        default: false
    }) hidePersonInfo: boolean;

    @Prop({
        default: 0
    }) slideIndex: number;
    localIndex: number = 0;

    affinityRateText: string = "";

    created() {
        this.localIndex = this.slideIndex;
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");   
    }

    get affinityFullText(){
        return this.affinityRateText + " " + this.affinity; 
    }

    openDescription(){
        if(!this.match.biography)
            return;

        this.$opModal.show(viewDescriptionModal, {
            ownDescription: false,
            completeName: this.match.name,
            birthData: this.match.birthDate,
            meters: 0,
            sexualOrientation: this.match.sexualOrientation,
            description: this.match.biography
        })
    }

}

import router from "@/router";
import store from "@/store";
import { getMessaging, getToken, Messaging, onMessage, Unsubscribe } from "firebase/messaging";
import { AppUserClient } from "../Services";
import { UrlServices } from "../UrlServices";
import { onBackgroundMessage } from "firebase/messaging/sw";
import * as OM from '@/Model';
import * as firebase from "firebase/app";
import { getViewWithServices } from "@/utils";
import { AskForIdfa } from "../IdfaServices";

var webPublicKey = "BFU1ykaZHBiBUDcRGew1Vj9TJZOTQo7FccuJsUEgfJGu3XPl6SaNs0GmVVifPTKs-k_2W6Nt-vTrV3UGNhFCKpo";
var notificationDuration = 4000;
var notificationLeaveAnimationTime = 500;

export function RegisterPush(userIdentifier: string){

    try {
        if(window.cordova)
            RegisterPushCordova(userIdentifier)
        else
            RegisterPushWeb();
    } catch( ex ) {
        console.log(ex)
    }
}

function RegisterPushCordova(userIdentifier: string){
    FirebasePlugin.hasPermission(function(hasPermission){
        if(hasPermission)
            RegisterPushNotification();
        else
            FirebasePlugin.grantPermission(function(hasPermission){

                if(hasPermission)
                    RegisterPushNotification();
                else {
                    setTimeout(() => {
                        AskForIdfa();
                    }, 3000);
                } 
                    
            })
    });

    FirebasePlugin.setUserId(userIdentifier);
}

function RegisterPushWeb(){
    if(firebase.getApps().length == 0)
        return;

    let messaging = getMessaging();
    
    Notification.requestPermission()
    .then((permission) => {
        if (permission === 'granted') {
            getToken(messaging, { vapidKey: webPublicKey })
            .then((currentToken) => {
                if (currentToken) {
                    AppUserClient.setFcmId(currentToken, false);
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    });
}

export function UnregisterPush(){
    if(!window.cordova){
        // navigator.serviceWorker.getRegistrations().then((r) => {
        //     return Promise.all(r.map(reg => reg.unregister()));
        // });
    } else {
        FirebasePlugin.setAutoInitEnabled(false, function(){
            FirebasePlugin.unregister();
        });
    }
}

function RegisterPushNotification(){
    // window.cordova.plugins.firebase.messaging.createChannel({
    //     id: "1",
    //     name: "Kpet Update",
    //     badge: true,
    //     vibration: true
    // }).then( x => {
    //     console.log(x)
    // });

    FirebasePlugin.getToken(function(fcmToken) {
        AppUserClient.setFcmId(fcmToken, false)
        .then( x => {
            setTimeout(() => {
                AskForIdfa();
            }, 3000);
        })
    }, function(error) {
        // console.log("FIREBASE PLUGIN GET TOKEN ERROR");
        // console.log(error)
    });

    // FirebasePlugin.onTokenRefresh(function(fcmToken) {
    //     UserClientWS.setFCMID(fcmToken)
    // }, function(error) {
    //     console.log("FIREBASE PLUGIN ON TOKEN REFRESH ERROR");
    //     console.error(error);
    // });
    
    FirebasePlugin.onMessageReceived(function(message) {
        // console.log("ECCO IL MESSAGGIO", message)
        
        //Notifica con app aperta
        if(!message.tap){

            //se è una notifica relativa alla route "/affinity" e sei nella route "/affinity" refresha la pagina e continua
            // if(isAFfinityNotification(message) && store.state.onAffinityNotificationReceivedInAffinitySection){
            //     store.state.onAffinityNotificationReceivedInAffinitySection(message);
            // }
            
            //se è una notifica di nuovo messaggio e sei nella route "/affinity" non proseguire
            // if(isNewMesasgeNotification(message) && store.state.onAffinityNotificationReceivedInAffinitySection)
            //     return;
            
            //Se sei nella route "/notification" e arriva una notifica non relativa ad "/affinity" aggiorna la pagina e non proseguire
            // if(!isNewMesasgeNotification(message) && store.state.onNotificationReceivedInNotificationSection){
            //     store.state.onNotificationReceivedInNotificationSection();
            //     return;
            // }
            
            pushNewNotification(message);
        }
        else if(message.tap){
            store.state.notificationFunction = () => {
                store.state.notificationFunction = null;
                notificationClicked(message);
            };
        }

        // console.log("New foreground FCM message: ", message);
        // console.log("Message type: " + message.messageType);
        // if(message.messageType === "notification"){
        //     console.log("Notification message received");
        //     if(message.tap){
        //         console.log("Tapped in " + message.tap);
        //     }
        // }
        // console.dir(message);
    }, function(error) {
        console.error(error);
    });
};

export function pushNewNotification(notification: OM.NotificationVM){
    store.state.notificationList.push(notification);

    if(isNewMesasgeNotification(notification))
        store.state.affinityNotificationNumber ++;
    else
        store.state.notificationNumber ++;

    newNotificationHasArrived();
}

export function isAFfinityNotification(message: any){
    var isNewAffinity = message.notificationType == OM.NotificationType.NewAffinity || (OM.NotificationType[message.notificationType] as any) == OM.NotificationType.NewAffinity;
    var isAffinityRemoved = message.notificationType == OM.NotificationType.AffinityRemoved || (OM.NotificationType[message.notificationType] as any) == OM.NotificationType.AffinityRemoved;
    var isNewMessage = message.notificationType == OM.NotificationType.NewMessage || (OM.NotificationType[message.notificationType] as any) == OM.NotificationType.NewMessage;

    if(isNewAffinity || isAffinityRemoved || isNewMessage)
        return true;

    return false;
}

export function isNewMesasgeNotification(message: any){
    return message.notificationType == OM.NotificationType.NewMessage || (<any>OM.NotificationType[message.notificationType]) == OM.NotificationType.NewMessage;
}

export function newNotificationHasArrived(){
    if(store.state.notification != null || loadingNextNotification)
        return;

    store.state.notification = store.state.notificationList[0];
    
    startNotificationTimer();
}

let loadingNextNotification = false;
export function clearNotificationAndCheckAnotherOne(){
    clearTimeout(timeoutHandler)
    remaining = null;

    store.state.notification = null;
    store.state.notificationList.splice(0, 1);

    if(loadingNextNotification)
        return;

    loadingNextNotification = true;

    if(store.state.notificationList.length > 0){
        setTimeout(() => {
            store.state.notification = store.state.notificationList[0];
            startNotificationTimer();
            loadingNextNotification = false;
        }, notificationLeaveAnimationTime);
    } else {
        loadingNextNotification = false;  
    }
}

let timeoutHandler = null;
let timeoutStart = null;
let remaining = null;

function startNotificationTimer(){
    timeoutStart = Date.now();

    var tempNotificationDuration = notificationDuration;

    if(remaining)
        tempNotificationDuration = remaining;

    remaining = null;

    timeoutHandler = setTimeout(() => {
        clearNotificationAndCheckAnotherOne()
    }, tempNotificationDuration);
}

export function notificationTimerPause(){
    remaining = Date.now() - timeoutStart;
    clearTimeout(timeoutHandler);
}

export function notificationTimerResume(){
    startNotificationTimer();
}

export function notificationClicked(message: any) : void {
    if(message && message.route){
        var route = message.route;
        router.push(route)
        getViewWithServices().$opModal.closeAll();
    } else if(message.targetUrl){
        UrlServices.openBlank(message.targetUrl);
    }

    clearNotificationAndCheckAnotherOne();
}

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class imageAndQuestions extends Vue {

    @Prop() profileQuestion: OM.ProfileQuestion | OM.Answer;
    @Prop() image: string;
    @Prop({
        default: false
    }) preview: boolean;

    @Prop({
        default: () => []
    }) previewImages: string[];

    myIndexSelected = 3;

    get backgroundImage(){
        if(!this.isMyProfile)
            return this.image;
        
        return this.previewImages[this.myIndexSelected];
    }

    getAffinityAnswer(index: number){
        var tempProfileQuestion = this.profileQuestion as OM.Answer;

        if(tempProfileQuestion.answerIndex || tempProfileQuestion.answerIndex == 0)
            return tempProfileQuestion.answersPercentage[index];
        
        return 100 - 25 * index;
    }

    getButtonClass(index: number){
        var classes = "";

        if(this.preview)
            classes +="preview ";

        var tempProfileQuestion = this.profileQuestion as OM.Answer;
        if(!this.isMyProfile && (tempProfileQuestion.answerIndex == null || tempProfileQuestion.answerIndex != index))
            classes += "box_shadow ";
        else if(this.isMyProfile && this.myIndexSelected != index)
            classes += "box_shadow ";

        if(!this.isMyProfile && tempProfileQuestion.answerIndex == index)
            classes += "blue ";
        else if(this.isMyProfile && this.myIndexSelected == index)
            classes += "blue ";

        return classes;
    }

    get isMyProfile(){
        return this.previewImages.length > 0;
    }

    clickedButton(index: number){
        if(!this.isMyProfile)
            return;

        this.myIndexSelected = index;
    }

    get isNewPhoto(){
        if(this.previewImages.length == 0)
            return false;

        var first = this.previewImages[0];

        return this.previewImages.every(function(element) {
            return element === first;
        });
    }

    get getNewPhotoClass(){
        if(!this.isNewPhoto)
            return "";

        return "blur_" + (100 - (25 * this.myIndexSelected)).toString(); 
    }
}

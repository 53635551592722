
import { Prop, Watch } from 'vue-property-decorator';
import { Swiper, SwiperOptions, Autoplay } from 'swiper';
import { Options, Vue } from 'vue-class-component';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';
import { moduleExpression } from '@babel/types';

@Options({

})
export default class SwiperSlides extends Vue {
    
    @Prop({
        type: Number,
        default: 0
    }) slideIndex: number;

    @Prop({
        default: true
    }) showPagination: boolean;
    
    @Prop({
        default: true
    }) autoHeight: boolean;

    @Prop({
        default: false
    }) autoPlay: boolean;

    @Prop({
        default: false
    }) loop: boolean;

    @Prop({
        default: false
    }) noSwiping: boolean;

    @Prop({
        default: 0
    })  itemsLenght: number;

    @Prop({
        default: 1200
    })  speed: number;

    @Prop({
        default: true
    })  allowTouchMove: boolean;

    @Prop({
        default: "horizontal"
    })  direction: "horizontal" | "vertical";

    @Prop({
        default: 'auto'
    })  slidesPerView: number | 'auto';

    @Prop({
        default: 20
    })  spaceBetween: number;

    @Prop({
        default: true
    }) allowSlideNext: boolean;

    activeBullet: number = 1;

    swiper: any;
    mounted(){
        var modules = [];

        if(this.autoPlay)
            modules.push(Autoplay);

        let swiperParams: SwiperOptions = {
            modules: modules,
            pagination: {
                el: <any>this.$refs.swiperPagination,
            },
            autoHeight: this.autoHeight,
            direction: this.direction,
            initialSlide: this.slideIndex,
            speed: this.speed,
            loop: this.loop,
            on: {
                slideChange: this.onSlideChange,
            },
            allowTouchMove: this.allowTouchMove,
            slidesPerView: this.slidesPerView,
            spaceBetween: this.spaceBetween,
            allowSlideNext: this.allowSlideNext,
            autoplay: this.autoPlay ? {
                delay: 1
            } : false
        };

        setTimeout(() => {
            this.swiper = new Swiper(<HTMLElement>this.$refs.swiperContainer, swiperParams);
            this.activeBullet = this.swiper.activeIndex + 1;

            if(this.autoPlay)
                this.swiper.autoplay.start();
        }, 600);        
    }

    @Watch("allowSlideNext")
    allowSlideNextChanged(){
        if(!this.swiper)
            return;

        this.swiper.allowSlideNext = this.allowSlideNext;
    }

    @Watch('slideIndex')
    onSlideIndexChange(next, prev){
        this.swiper.slideTo(next);
    }

    onSlideChange() {
        if(!this.swiper)
            return;
            
        this.activeBullet = this.swiper.activeIndex + 1;
        this.$emit('slidechange', this.swiper.activeIndex);
    }

}


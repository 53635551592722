import { createStore } from 'vuex';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { LocalizationServices } from '@/services/LocalizationServices';

let store = {
    state: {
        showSpinner: 0,
        selectedLanguage: "",
        referralCode: "",
        currentPosition: <OM.Address>null,
        registeredPhoneNumber: new VM.PhoneNumber(),
        consts: {
            localizedValues: <OM.LocalizedEntity[]>[],
            enabledLanguages: <string[]>[],
            extendedCountries: <OM.ExtendedCountry[]>[],
            config: new OM.SystemConfigurationVM(),
            ownGenderList: <OM.TextIdentifier[]>[],
            genderList: <OM.TextIdentifier[]>[],
            sexualOrientation: <OM.TextIdentifier[]>[],
        },
        notificationNumber: 0,
        notification: null,
        notificationList: [],
        affinityNotificationNumber: 0,
        notificationFunction: () => null,
        loggedUser: new OM.LoggedUserVM(),
        deviceReady: false,
        timedMessage: "",
        heightWindow: "",
        premiumActiveModalSeen: false,
        afterPremiumAction: <() => Promise<void>>null,
        openSecondChanceCb: <(secondChanceIdentifier: string) => void>null,
        experienceOpenSecondChanceCb: <(item: OM.ApplicantVM) => void>null
    },
    mutations: {
        updatedLanguage(state){
            state.consts.sexualOrientation = state.consts.config.sexualOrientations.map( el => {
                var vm = new OM.TextIdentifier();
                vm.identifier = el;
                vm.text = LocalizationServices.getLocalizedValue("app_" + el, el);

                return vm;
            })

            var genderList: OM.TextIdentifier[] = [];
            var ownGenderList: OM.TextIdentifier[] = [];

            var keys = Object.keys(OM.Gender);
            keys.slice(0, keys.length / 2).forEach( el => {
                var vm = new OM.TextIdentifier();
                vm.identifier = el;
                vm.text = LocalizationServices.getLocalizedValue("app_lookingfor_" + OM.Gender[el], OM.Gender[el])

                genderList.push({...vm});

                vm.text = LocalizationServices.getLocalizedValue("app_owngender_"  + OM.Gender[el], OM.Gender[el]);
                ownGenderList.push(vm)
            })

            state.consts.genderList = genderList;
            state.consts.ownGenderList = ownGenderList;
        }
    },
    actions: {
    },
    modules: {
    },
    getters: {
    }
}

export default createStore(store);

import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class TimedMessage extends Vue {

    mounted() {
        this.textChanged();
    }

    get text(){
        return store.state.timedMessage;
    }

    @Watch("text")
    textChanged(){
        if(!this.text)
            return;
        
        setTimeout(() => {
            store.state.timedMessage = "";
        }, 2000);
    }

}

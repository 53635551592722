
import { Options, Vue } from 'vue-class-component';

@Options({
    components: {
    }
})
export default class OldIosVersionModal extends Vue {

    created(){
    }

    mounted(){
    }

}


import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';
import InviteFriendForGratisPremiumModal from '@/views/loggeduser/modals/inviteFriendForGratisPremiumModal.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ReferralCodeModal from './modals/referralCodeModal.vue';

@Options({})
export default class ReferralCodeButton extends Vue {

    @Prop() referralCode: string;
    @Prop({
        default: false
    }) isButton: boolean;

    title = "";
    message = "";
    // url = "";

    // text?: string;
    // title?: string;
    // url?: string;
    // files?: File[];
    webOptions = null;

    // message: 'share this', // not supported on some apps (Facebook, Instagram)
    // subject: 'the subject', // fi. for email
    // files: ['', ''], // an array of filenames either locally or remotely
    // url: 'https://www.website.com/foo/#bar?a=b',
    // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
    // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height

    // openReferral(){
    //     this.$opModal.show(ReferralCodeModal, {
    //         referralCode: this.referralCode
    //     })
    // }
    cordovaOptions = null;

    created(){
        this.title = LocalizationServices.getLocalizedValue("share_invitaUnAmicoTitle", "Feven - Invita un amico");
        this.message = LocalizationServices.getLocalizedValue("share_invitaUnAmicoText{{referralCode}}", 
            "Ciao,\nScarica Feven e utilizza il mio codice per ricevere una settimana di premium gratis!\n\n {{referralCode}}", 
            "",
            "",
            { referralCode: this.referralCode});
        this.init();
    }

    @Watch("referralCode")
    init(){
        // var shareUrl = store.state.consts.config.referralConfiguration.referralLink;
        // this.url = LocalizationServices.replaceText(shareUrl, { code: this.referralCode});

        this.cordovaOptions = {
            subject: this.title,
            message:  this.message = LocalizationServices.getLocalizedValue("share_invitaUnAmicoText{{referralCode}}", 
                "Ciao,\nScarica Feven e utilizza il mio codice per ricevere una settimana di premium gratis!\n\n {{referralCode}}", "", "",
                { referralCode: this.referralCode})
            // url: this.url,
        }

        this.webOptions = {
            title: this.title,
            text:  this.message = LocalizationServices.getLocalizedValue("share_invitaUnAmicoText{{referralCode}}", 
            "Ciao,\nScarica Feven e utilizza il mio codice per ricevere una settimana di premium gratis!\n\n {{referralCode}}", "", "",
                { referralCode: this.referralCode})
            // url: this.url
        }
    }

    openInviteFriendModal() {
        this.$opModal.show(InviteFriendForGratisPremiumModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.inviteFriend();
            }
        })
    }

    inviteFriend(){
       if(window.cordova)
            this.cordovaShare();
        else 
            this.webShare();
    }

    cordovaShare(){
        window.plugins.socialsharing.shareWithOptions(this.cordovaOptions, this.onSuccess, this.onError);
    }

    webShare(){
        navigator.share(this.webOptions)
    }

    onSuccess = function(result) {
        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    onError = function(msg) {
        console.log("Sharing failed with message: " + msg);
    };
}

//CORDOVA

import { SaveFileVM, fileName } from "./AudioRecorderServices";

let cordovaChunks: Float32Array[] = [];

let cordovaIntervalDuration;
let cordovaDuration = 0;

let audioInputCfg = {
    sampleRate: 44100,
    bufferSize: 2048,
    channels: 1,
    format: "PCM_16BIT",
    audioSourceType: 0,
    streamToWebAudio: false
};

export function requestCordova(): Promise<void>{
    let prom = new Promise<void>( (resolve, reject) => {
        const permissions = window.cordova.plugins.permissions;
        const requiredPermissions = [
            permissions.RECORD_AUDIO,
            permissions.MODIFY_AUDIO_SETTINGS,
            permissions.WRITE_EXTERNAL_STORAGE,
            permissions.READ_EXTERNAL_STORAGE,
        ];

        permissions.requestPermissions(requiredPermissions, () => {
            resolve();
        }, (err) => {
            reject("Concedi l'accesso al microfono a Feven dalle impostazioni del tuo smartphone!");
        });
    })

    return prom;
}


let audioInputFunction = null;
let audioInputErrorFunction = null;
export function startCordova( onError : (error: string) => void, onVolumeIntensityAdded: (intensity: number) => void, 
    onDurationUpdated: (duration: number) => void, maxValue: number){
    
    cordovaChunks.splice(0);
    cordovaDuration = 0;
    onDurationUpdated(cordovaDuration);
    
    if(!audioInputFunction && !audioInputErrorFunction){
        audioInputFunction = (event) => onAudioInput(event, onVolumeIntensityAdded, maxValue);
        audioInputErrorFunction = (evt) => onAudioInputError(evt, onError);
    }
    
    window.addEventListener( "audioinput", audioInputFunction, false );
    window.addEventListener( "audioinputerror", audioInputErrorFunction, false);

    audioinput.start(audioInputCfg);

    cordovaIntervalDuration = setInterval(() => {
        cordovaDuration += 1;
        onDurationUpdated(cordovaDuration);
    }, 1000);
}

export function stopCordova(onStop: (saveFileVM: SaveFileVM) => void, onError: (url: string) => void, maxValue: number){

    audioinput.stop();
    
    window.removeEventListener( "audioinput", audioInputFunction, false);
    window.removeEventListener( "audioinputerror", audioInputErrorFunction, false);
    
    clearInterval(cordovaIntervalDuration);
    
    // Unisci i chunk di dati Float32Array in un singolo array
    var mergedFloat32Data = new Float32Array(cordovaChunks.reduce((a, b) => a.concat(Array.from(b)), []));

    // Converti i dati Float32Array in Int16Array
    var mergedInt16Data = convertFloat32ArrayToInt16Array(mergedFloat32Data);

    saveAudioFile(mergedInt16Data)
    .then( x => {
        onStop(x);
    }).catch( err => {
        onError(err);
    })
}

function onAudioInput( event, onVolumeIntensityAdded: (intensity: number) => void, maxValue: number) {
    // 'event.data' is an integer array containing raw audio data
    var data = event.data;
    
    let volume = calculateRMS(data);

    var scaledVolume = scaleValue(volume, 1, maxValue);
    onVolumeIntensityAdded(scaledVolume);

    cordovaChunks.push(new Float32Array(data));
}

function calculateRMS(audioData) {
    let sum = 0;
    for (let i = 0; i < audioData.length; i++) {
      sum += audioData[i] * audioData[i];
    }
    let rms = Math.sqrt(sum / audioData.length);
    return rms;
}

const oldMin = 0;
const oldMax = 0.6;
function scaleValue(value, newMin, newMax) {
    let oldRange = oldMax - oldMin; // Calcola il vecchio range
    let newRange = newMax - newMin; // Calcola il nuovo range
    let newValue = (((value - oldMin) * newRange) / oldRange) + newMin;
    return newValue;
}

function saveAudioFile(pcmData) {
    let prom = new Promise<SaveFileVM>((resolve, reject) => {
    
        var mp3Encoder = new lamejs.Mp3Encoder(audioInputCfg.channels, audioInputCfg.sampleRate, 128);
    
        // Converti i dati PCM in MP3
        var mp3Data = mp3Encoder.encodeBuffer(pcmData);
        var mp3Blob = new Blob([mp3Data], { type: 'audio/mpeg' });
        var directory = window.cordova.file.externalDataDirectory;

        if(window.cordova.platformId === 'ios')
            directory = window.cordova.file.documentsDirectory;

        // Salva il Blob MP3 come file usando il plugin File di Cordova
        window.resolveLocalFileSystemURL(directory, (dirEntry) => {
            dirEntry.getFile(fileName(), { create: true, exclusive: false }, (fileEntry) => {
                fileEntry.createWriter((fileWriter) => {
                    fileWriter.onwriteend = () => {

                        var url = fileEntry.toURL();
                        var fileUrl = fileEntry.toURL();

                        if(window.cordova.platformId === 'ios')
                            url = window.WkWebView.convertFilePath(url);
                            
                        var saveFileVM: SaveFileVM = {
                            url: url,
                            file: fileUrl,
                        };

                        resolve(saveFileVM);
                    };
            
                    fileWriter.onerror = (e) => {
                        reject("Errore durante il salvataggio del file audio MP3! Concedi l'accesso alla memoria a Feven.");
                    };
            
                    // Scrive il Blob MP3 nel file
                    fileWriter.write(mp3Blob);
                });
            });
        });
    });

    return prom;
}

function onAudioInputError( error, onError : (error: string) => void ) {
    onError(error);
};

function convertFloat32ArrayToInt16Array(float32Array) {
    var int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      int16Array[i] = float32Array[i] * 32767;
    }
    return int16Array;
}
import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import { LocalizationServices } from './LocalizationServices';
import { AppUserClient } from './Services';
import { RegisterPush, UnregisterPush } from './Firebase/FirebasePushServices';
import router from '@/router';
import store from '@/store';
import * as OM from '@/Model';
import { getViewWithServices } from '@/utils';

class _LoginServices {
    
    // CheckLogin(component){
    //     var loggedUser = StorageServices.getLoggedUser();
	// 	if(loggedUser && loggedUser.token){
    //         this.LoginCallback(loggedUser.token, component)
    //     } else
    //         router.replace('/shareposition');
    // }

    LoginCallback(token: string, refreshToken: string, gotoprofile: boolean = false): Promise<void>{
        var prom = new Promise<void>((resolve, reject) => {
            if(window.location.hash == "#/deleteaccount"){
                resolve();
                return
            }

            if(!token){
                LoginServices.logout();
                reject();
                return;
            }
            
            StorageServices.setNewTokens(token, refreshToken);
    
            LocalizationServices.getCurrentLocation()
            .then( async x => {
                this.tokenUpgrade(gotoprofile)
                .then( x => {
                    resolve();
                }).catch( ex => {
                    console.log(ex)
                    reject();
                });
            }).catch( x => {
                LoginServices.logout();
                reject()
                return; 
            })
        })
        
        return prom;
    };

    private tokenUpgrade(gotoprofile: boolean = false){
        var prom = new Promise<void>((resolve, reject) => {
            Promise.all([
                AppUserClient.updateUserPosition(store.state.currentPosition),
                AppUserClient.tokenUpgrade()
            ])
            .then( x => {
                var loggedUser = x[1];
                
                RegisterPush(loggedUser.identifier);

                StorageServices.setLoggedUser(loggedUser);
                store.state.loggedUser = loggedUser;
                store.state.selectedLanguage = loggedUser.preferredCulture;
                
                StorageServices.setNewTokens(x[1].token, x[1].refreshToken);
                
                if(!loggedUser.emailAddress)
                    router.push("/insertemail")
                else if(!loggedUser.onBoardingCompleted)
                    router.push("/completeonboarding");
                else if(gotoprofile){
                    router.push("/finder")
                    .then( () => {
                        router.push("/profile");
                    });
                }
                else
                    router.push("/finder");
                    
                resolve();
            })
            .catch( err => {
                LoginServices.logout();
                reject();
            });
        })
        
        return prom;
    }

    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            //Unregistro le push notification
            UnregisterPush();

            //Resetto le credenziali dell'autenticazione
            CommonServices.destroyToken();
            StorageServices.setLoginRefreshToken("");
            this.resetLoggedUser();

            //Chiudo tutte le modali
            var viewWithServices = getViewWithServices();
            viewWithServices.$opModal.closeAll();

            //Nascondo il loader
            store.state.showSpinner = 0;

            //Rimando alla login
            router.replace('/');
            resolve();
        })
        return prom;
    }


    private resetLoggedUser() {
        var loggedUser = new OM.LoggedUserVM();
        loggedUser.preferredCulture = StorageServices.getLoggedUser().preferredCulture;
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();
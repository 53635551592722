
import { Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from './services/StorageServices';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import store from './store';
import splash from './views/splash.vue';
import InfoModal from './components/modals/infoModal.vue';
import Smartlook from 'smartlook-client';
import { smartlookAPIKey } from './config';
import { assignViewWithServices } from './utils';

@Options({
    components: {
        SpinnerContainer,
        splash
    }
})
export default class App extends Vue {

    canShow: boolean = false;
    height: string = "";
    keyboardHeight: number = 0;
    keyboardOpened: boolean = false;

    @Watch("$store.state.loggedUser.preferredCulture")
    languageChanged() {
        if(!store.state.consts.config || store.state.consts.localizedValues.length == 0)
            return;

        store.commit("updatedLanguage")
    }
    
    created() {
        assignViewWithServices(this);
        store.state.loggedUser = StorageServices.getLoggedUser();
        store.state.selectedLanguage = store.state.loggedUser.preferredCulture;
        store.state.currentPosition = StorageServices.getCurrentPosition();
        store.state.registeredPhoneNumber = StorageServices.getRegisteredPhoneNumber();
        this.registerInAppBrowser();
        this.registerDeviceReadyFunction();
    }

    mounted() {
        store.state.heightWindow = window.innerHeight.toString() + "px";
        this.registerWebSmartLook();
    }

    get showSplash(){
        return !window.cordova || store.state.deviceReady;
    }

    registerWebSmartLook(){
        if(window.cordova || !smartlookAPIKey)
            return;

        Smartlook.init(smartlookAPIKey);

        // var script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.innerHTML = `window.smartlook||(function(d) { var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0]; var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript'; c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c); })(document); smartlook('init', 'd3e32a5c089fa50e8f2cd1a724933f2ce6207bd5', { region: 'eu' });`;

        // document.head.appendChild(script);       
    }

    get getBackground(){
        var colorClass = this.$route.meta.bgBlue ? "theme_blue" : "theme_white";;

        var body = document.body;
        body.classList.remove("theme_blue");
        body.classList.remove("theme_white");

        body.classList.add(colorClass);

        return colorClass;
    }

    registerInAppBrowser(){
        if(!window.cordova)
            return;

        document.onclick = function (e: any) {
            e = e ||  window.event;
            var element = e.target || e.srcElement;

            var path = e.path || e.composedPath();
            if (path.some(x => x.tagName == 'A' && 
                (
                    (x.target == '_blank' || x.target == '_self') 
                    || x.href.indexOf('mailto:') != -1
                    || x.href.indexOf('tel:') != -1
                ))) {
                    
                e.preventDefault();
                let href = path.find(x => x.tagName == 'A').href;
                
                window.open(href, "_system")

                return false; // prevent default action and stop event propagation
            }
        };
    }

    registerDeviceReadyFunction(){
        if(!window.cordova)
            return;

        document.addEventListener("deviceready", () => {
            this.registerBackButton();
        }, false)
    }

    registerBackButton(){
        document.addEventListener("backbutton", () => {
            if(this.$opModal.modals().length > 0){
                this.$opModal.closeLast();
                return;
            }

            if(this.$route.name == "finder" || this.$route.name == "select language") {
                this.$opModal.show(InfoModal, {
                    text: this.$localizationService.getLocalizedValue("app_VuoiChiudereLApp?", "Vuoi chiudere l'app?"),
                    confirm: this.$localizationService.getLocalizedValue("app_Esci", "Esci"),
                    deny: this.$localizationService.getLocalizedValue("app_Resta", "Resta"),
                    confirmCb: () => {
                        this.$opModal.closeLast();

                        if(window.cordova)
                            (<any>window.navigator).app.exitApp();
                    },
                    denyCb: () => {
                        this.$opModal.closeLast();
                    }
                })
            } else {
                this.$router.back();
            }
        }, false);
    }

    @Watch("$store.state.showSpinner")
    disableScroll(){
        var el = document.getElementById("body");
        
        if(el == null)
          return;
        
        if(store.state.showSpinner > 0)
            el.classList.add("overflow-hidden");
        else
            el.classList.remove("overflow-hidden");
    }
}


import { Options, Vue, mixins } from 'vue-class-component';
import * as AudioRecorderServices from '@/services/AudioRecorder/AudioRecorderServices';
import { ExperienceClient } from '@/services/Services';
import { SaveFileVM } from '@/services/AudioRecorder/AudioRecorderServices';
import InfoModal from './modals/infoModal.vue';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import WithModel from './standard/formFieldPlugin/mixins/withModel';

@Options({
    components: {

    }
})
export default class recorderAudioComponent extends mixins(WithModel) {
    
    @Prop({
        default: ""
    }) experienceUrl: string;

    @Prop({
        default: false
    }) isRecording: boolean;

    chunksIntensity: number[] = [];

    duration: number = 0;
    maxValue: number = 80;

    canClickAgain: boolean = true;

    created(){
    }

    get sourceUrl(){
        if(this.localValue.url)
            return this.localValue.url;

        return this.experienceUrl;
    }

    startRecording(){
        if(this.$refs.audio_player)
            (this.$refs.audio_player as any).stop();
    
        this.canClickAgain = false;
        this.chunksIntensity.splice(0);

        this.emitIsRecording(true);
        AudioRecorderServices.request()
        .then(() => {
            this.duration = 0;
            this.localValue = new AudioRecorderServices.SaveFileVM();
            AudioRecorderServices.start(this.onStop, this.onError, this.onVolumeIntensityAdded, this.onDurationUpdated, this.maxValue);
        })
        .catch( errorMessage => {
            this.emitIsRecording(false);
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: this.$localizationService.getLocalizedValue("app_" + errorMessage, errorMessage),
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })

    }

    onError(error: string){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
            subtitle: this.$localizationService.getLocalizedValue("app_" + error, error),
            deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    onVolumeIntensityAdded(intensity: number) {
        this.chunksIntensity.push(intensity);
        this.scrollChunks();
    }

    onDurationUpdated(duration: number){
        this.duration = duration;
    }

    stop(){
        AudioRecorderServices.stop(this.onStop, this.onError, this.maxValue);
    }

    onStop(saveFileVM: AudioRecorderServices.SaveFileVM){
        this.localValue = saveFileVM;
        this.emitIsRecording(false);
    }

    scrollChunks() {
        var soundBarContainer = <HTMLElement>this.$refs.audio_scrollable;
        var scrollWidth = soundBarContainer.scrollWidth;

        soundBarContainer.scrollLeft = scrollWidth;
    }

    emitIsRecording(value: boolean){
        this.$emit("update:isRecording", value);
        this.canClickAgain = true;
    }

}

import { mixins, Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from './withModel';
import * as OM from '@/Model'

@Options({
    
})
export class BaseFormField extends mixins(WithModel) {
    
    //common prop
    @Prop() type: string;
    @Prop() labelKey: string;
    @Prop() labelValue: string;
    @Prop({
        default: ""
    }) prevText: string;

    @Prop({
        default: ""
    }) afterText: string;

    @Prop() placeholderKey: string;
    @Prop() placeholderValue: string;
    @Prop({
        default: ""
    }) placeholderPrevText: string;

    @Prop({
        default: ""
    }) placeholderAfterText: string;

    @Prop() required: boolean;
    @Prop() readonly: boolean;
    @Prop() tabIndex: number;

    @Prop() maxlength: number;

    //number prop
    @Prop() step: number;
    @Prop() min: number;
    @Prop() max: number;
    
    @Prop() list: any[];
    @Prop() color: string;

    @Prop() prefix: string;
    @Prop() checkboxIsSelected: boolean;
    @Prop() fileName: string;
    @Prop() fileModeIcon: boolean;
    @Prop({
        default: false
    }) whiteBg: boolean;
    @Prop() validationCallback: (value: any) => boolean;


    @Prop() codeLength: number;

    @Prop({
        default: () => new OM.UserAddress()
    }) userAddress: OM.UserAddress;


    validationClass: string = "";
    localPrefix: string = "";

    inputElement: HTMLElement = null;

    created(){
        this.localPrefix = this.prefix;
        this.validationClassRoutine(this.modelValue);
    }

    @Watch('prefix')
    changeLocalPrefix(){
        this.localPrefix = this.prefix;
    }
    
    @Watch('modelValue')
    onModelValueChangeBaseFormField(next, prev){
        this.validationClassRoutine(next);
        this.onModelValueChange(next, prev);
    }
    validationClassRoutine(val: any){
        this.validationClass = "";
        if(!this.required){
            if(!val)
                return;
        }
        if(this.validationCallback){
            this.validationClass = this.validationCallback(val) ? 'valid' : 'invalid';
        }
        if(this.required){
            if(val){
                this.validationClass = 'valid';
            } else {
                this.validationClass = 'invalid';
            }
        }
    }

    @Watch("localPrefix")
    emitPrefix(){
        this.$emit("update:prefix", this.localPrefix);
    }

    get completeAddress(){
        if(this.type != "google-get-list")
            return "";
        
        if(this.modelValue.changed && !this.modelValue.addressFixed)
            return this.$localizationService.getMyPositionText();
        else if(this.modelValue.changed && this.modelValue.addressFixed)
            return this.modelValue.description;

        var isMyPosition = !this.userAddress.addressFixed;

        if(isMyPosition)
            return this.$localizationService.getMyPositionText();

        return this.$filters.getLocation(this.userAddress);
    }
}

import { Options, Vue } from 'vue-class-component';
import { AppUserClient, StripeClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import { Prop } from 'vue-property-decorator';
import StripeForm from './stripeForm.vue';
import * as OM from '@/Model';
import { CommonServices } from '@/services/CommonServices';
import store from '@/store';

@Options({
    components: {
        StripeForm
    }
})
export default class StripeSubscription extends Vue {

    @Prop() stripePriceId: string = "";
    @Prop() premiumIdentifier: string = "";

    subscriptionResult: OM.SubscriptionCreateResponseVm = new OM.SubscriptionCreateResponseVm();
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();
    loaded: boolean = false;

    created() {
        this.loggedUser = StorageServices.getLoggedUser();
        StripeClient.createSubscriptionIntent(this.stripePriceId)
        .then(x => {
            this.subscriptionResult = x;
            this.loaded = true;
        })
    }

    confirmPayment(result: any) {
        var model = new OM.ConfirmSubscriptionPurchaseVm();
        model.paymentIdentifier = result.paymentIntent.id;
        model.subscriptionIdentifier = this.subscriptionResult.subscriptionId;
        model.premiumIdentifier = this.premiumIdentifier;

        StripeClient.confirmSubscriptionPurchase(model)
        .then(x => {
            store.state.premiumActiveModalSeen = true;
            this.$emit('paymentCompleted');
        })
    }

}



import { Options, Vue } from 'vue-class-component';
import { StripeClient } from '@/services/Services';
import { stripeKey } from '@/config';
import { Prop } from 'vue-property-decorator';
import StripeSavedCards from './stripeSavedCards.vue';
import * as OM from '@/Model';
import { CommonServices } from '@/services/CommonServices';
import InfoModal from '../modals/infoModal.vue';

@Options({
    components: {
        StripeSavedCards
    }
})
export default class StripeForm extends Vue {
    
    @Prop() clientSecret: string;
    @Prop() user: OM.LoggedUserVM;

    paid: boolean = false;
    paying: boolean = false;
    cards: OM.StripeCardDetailVm[] = null;
    
    stripeKey = stripeKey;
    
    showNewCard: boolean = false;
    selectedCard: string = "";
    // saveCard: boolean = true;
    showError: boolean = false;

    stripe: any;
    cardNumber: any;
    cardExpiry: any;
    cardCvc: any;
    cardholderName: string = "";

    created() {
        console.log("inizializzato form component");
        this.stripe = Stripe(this.stripeKey);

        StripeClient.getSavedCards()
        .then(x => {
            this.cards = x;
            console.log(this.cards);
            if(this.cards.length == 0){
                this.setShowNewCard();
            }
        })
        .catch(y => {
            this.setShowNewCard();
        })
    }

    setShowNewCard() {
        this.showNewCard = true;
        this.selectedCard = "";
        this.$nextTick(() => {
            this.mountStripe();
        });
    }

    mountStripe() {
        this.showNewCard = true;
        this.selectedCard = '';
        var elements = this.stripe.elements();
        var style = {
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        };
        // this.card = elements.create("card", { hidePostalCode: true, style: style });
        // this.card.mount(this.$refs.cardelement);
        this.cardNumber = elements.create('cardNumber', {
            classes: { base: "stripeInput" }
        });
        this.cardNumber.mount(this.$refs.cardnumber);

        this.cardExpiry = elements.create('cardExpiry', {
            classes: { base: "stripeInput" }
        });
        this.cardExpiry.mount(this.$refs.cardexpiry);
        
        this.cardCvc = elements.create('cardCvc', {
            classes: { base: "stripeInput" }
        });
        this.cardCvc.mount(this.$refs.cardcvc);

        this.cardNumber.addEventListener('change', ({error}) => {
            const displayError = <HTMLElement>this.$refs.carderrors;
            if (error) {
                displayError.textContent = error.message;
                this.showError = true;
            } else {
                displayError.textContent = '';
                this.showError = false;
            }
        });
    }

    get canPay() {
        return !!this.clientSecret && this.cardholderName.trim();
    }

    pay() {
        CommonServices.showSpinner();
        this.paying = true;
		if(this.selectedCard != "") {
            this.confirmCardPayment(this.selectedCard);
		} else {
            // this.stripe.createToken(this.card)
            this.stripe.createToken(this.cardNumber)
            .then(result => {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = <HTMLElement>this.$refs.carderrors;
                    errorElement.textContent = result.error.message;
                    this.paying = false;
                    CommonServices.hideSpinner();
                } else {
                    this.confirmCardPayment({
                        card: {
                            token: result.token.id
                        },
                        billing_details: {
                            name: this.user.completeName,
                        },
                    })
                }
            })
		}
		
    }

    confirmCardPayment(payment_method) {
        this.stripe.confirmCardPayment(this.clientSecret, {
            payment_method: payment_method,
        })
        .then(async (paymentResult) => {
            if(paymentResult.error){
                var errorElement = <HTMLElement>this.$refs.carderrors;
                errorElement.textContent = paymentResult.error.message;
                this.paying = false;
                CommonServices.hideSpinner();
            } else {
                this.paying = false;
                // if(this.saveCard){
                //     await this.addCardToCustomer();
                // }
                CommonServices.hideSpinner();
                this.$emit('complete', paymentResult);
            }
        }).catch( ex => {
            CommonServices.hideSpinner();
            this.paying = false;

            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        });;
    }

    // addCardToCustomer(){
    //     return new Promise<void>((resolve, reject) => {
    //         this.stripe.createPaymentMethod({
    //             type: 'card',
    //             card: this.cardNumber,
    //             billing_details: {
    //                 name: this.cardholderName,
    //             }
    //         })
    //         .then((result) => {
    //             if (result.error) {
    //                 alert(result.error);
    //             } else {
    //                 StripeClient.addCardToCustomer({
    //                     userIdentifier: this.user.identifier,
    //                     paymentMethodId: result.paymentMethod.id
    //                 })
    //                 .then(x => {
    //                     resolve();
    //                 })
    //             }
    //         });
    //     })
    // }

}


import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stripe_form = _resolveComponent("stripe-form")

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_stripe_form, {
          clientSecret: _ctx.subscriptionResult.clientSecret,
          user: _ctx.loggedUser,
          onComplete: _ctx.confirmPayment
        }, null, 8, ["clientSecret", "user", "onComplete"])
      ]))
    : _createCommentVNode("", true)
}

import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class Countdown extends Vue {

    @Prop({
        default: null
    }) endDate: Date;

    interval: any;

    created() {}

    mounted() {      
        var self = this;  
        this.interval = setInterval(function() {
            self.checkTimer();
        }, 1000);
    }

    checkTimer() {
        if(!this.endDate)
            return;
            
		var countDownDate = this.endDate.getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var stringDays = days.toString();
        if(days < 10)
            stringDays = "0" + days;

        var stringHours = hours.toString();
        if(hours < 10)
            stringHours = "0" + hours;

        var stringMinutes = minutes.toString();
        if(minutes < 10)
            stringMinutes = "0" + minutes;

		var stringSeconds = seconds.toString();
        if(seconds < 10)
            stringSeconds = "0" + seconds;

        (<any>this.$refs.days).innerHTML = stringDays;
        (<any>this.$refs.hours).innerHTML = stringHours;
        (<any>this.$refs.minutes).innerHTML = stringMinutes;
		(<any>this.$refs.seconds).innerHTML = stringSeconds;

        if (distance < 0) {
            clearInterval(this.interval);
            this.$emit('countdownEnded');
        }
	}

    unmounted() {
        clearInterval(this.interval);
    }

}

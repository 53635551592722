export function AskForIdfa(){

    return new Promise<void>((resolve, reject) => {
        if(!window.cordova || !window.cordova.plugins || !window.cordova.plugins.idfa){
            console.log("resolving 1");
            resolve();
            return;
        }

        window.cordova.plugins.idfa.getInfo()
        .then(info => {
            if (!info.trackingLimited) {
                info.idfa || info.aaid;
                console.log("resolving 2");
                resolve();
            } else if (info.trackingPermission === window.cordova.plugins.idfa.TRACKING_PERMISSION_NOT_DETERMINED) {
                window.cordova.plugins.idfa.requestPermission()
                .then( result => {
                    if (result === window.cordova.plugins.idfa.TRACKING_PERMISSION_AUTHORIZED) {
                        window.cordova.plugins.idfa.getInfo()
                        .then(info => {
                            info.idfa || info.aaid;
                            console.log("resolving 3");
                            resolve();
                        });
                    } else if (result === window.cordova.plugins.idfa.TRACKING_PERMISSION_DENIED) {
                        console.log("resolving 4");
                        resolve();
                    }
                });
            }
        })
        .then(idfaOrAaid => {
            if (idfaOrAaid) {
                console.log(idfaOrAaid);
            }
        });
    })

}

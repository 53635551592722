
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class AddFavoriteButton extends Vue {

    @Prop({
        default: ""
    }) title: string;

    @Prop({
        default: false
    }) isOpened: boolean;
    localIsOpened: boolean = false;

    created() {
        this.localIsOpened = this.isOpened;
    }

    @Watch('isOpened')
    updateLocal() {
        this.localIsOpened = this.isOpened;
    }

}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as VM from "@/viewModel";
import * as OM from "@/Model";

@Options({})
export default class imageAndQuestionsChat extends Vue {

    @Prop() profileQuestion: OM.ProfileQuestion | OM.Answer;
    @Prop() image: string;
    @Prop() questionIndex: number;
    @Prop() affinityIdentifier: string;

    @Prop({
        default: () => []
    }) previewImages: string[];

    myIndexSelected = 3;

    getAffinityAnswer(index: number) {
        var tempProfileQuestion = this.profileQuestion as OM.Answer;

        if(tempProfileQuestion.answerIndex || tempProfileQuestion.answerIndex == 0)
            return tempProfileQuestion.answersPercentage[index];
        
        return 100 - 25 * index;
    }

    getButtonClass(index: number) {
        var classes = "preview ";

        var tempProfileQuestion = this.profileQuestion as OM.Answer;
        if((tempProfileQuestion.answerIndex == null || tempProfileQuestion.answerIndex != index))
            classes += "box_shadow ";
        else if(tempProfileQuestion.answerIndex == index)
            classes += "blue ";

        return classes;
    }

    clickedButton(index: number) {
        var model = new OM.ProfileQuestionAttached();
        model.affintyRate = this.getAffinityAnswer(index);
        model.question = this.profileQuestion.question;
        model.answer = this.profileQuestion.answers[index];
        model.imageUrl = this.image;
        model.questionIndex = this.questionIndex;
        model.answerIndex = index;
        model.matchIdentifier = this.affinityIdentifier;
        this.$emit('answerClicked', model);
    }
}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({})
export default class imageAndQuestionsPreview extends Vue {

    @Prop() profileQuestion: OM.ProfileQuestion | OM.Answer;
    @Prop() image: string;

    @Prop({
        default: () => []
    }) previewImages: string[];

    myIndexSelected = 3;

    getAffinityAnswer(index: number){
        var tempProfileQuestion = this.profileQuestion as OM.Answer;

        if(tempProfileQuestion.answerIndex || tempProfileQuestion.answerIndex == 0)
            return tempProfileQuestion.answersPercentage[index];
        
        return 100 - 25 * index;
    }

    getButtonClass(index: number){
        var classes = "preview ";

        if(this.myIndexSelected == index)
            classes += "blue ";
        else
            classes += "box_shadow ";

        return classes;
    }

    clickedButton(index: number){
        this.myIndexSelected = index;
    }

    get isNewPhoto(){
        if(this.previewImages.length == 0)
            return false;

        var first = this.previewImages[0];

        return this.previewImages.every(function(element) {
            return element === first;
        });
    }

    get getNewPhotoClass(){
        if(!this.isNewPhoto)
            return "";

        return "blur_" + (100 - (25 * this.myIndexSelected)).toString(); 
    }
}

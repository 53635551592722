
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class AppInfoModal extends Vue {

    @Prop({
        default: () => new OM.AppModalVM()
    }) model: OM.AppModalVM;

    created() {
    }

    doAction() {
        if(!this.model.actionUrl)
            this.$opModal.closeLast();
        else {
            this.$opModal.closeLast();
            this.$router.push(this.model.actionUrl);
        }
    }

}


import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from "@/Model";

@Options({
    components: {
    }
})
export default class InfoCompleteProfileModal extends Vue {

    @Prop({ default: false }) onboardingpath: boolean;
    @Prop() confirm: () => void;
    @Prop() deny: () => void;

    slideIndex: number = 0;
    activeIndex = 0;

    created(){
    }

    mounted(){
        this.playAnimation();
    }

    @Watch("slideIndex")
    isActiveChanged(){
        if(this.slideIndex != 0){
            this.activeIndex = 0;
            clearInterval(this.timeoutHandler);
            return;
        }
        
        this.playAnimation();
    }

    timeoutHandler;
    playAnimation(){
        this.timeoutHandler = setTimeout(() => {
            this.activeIndex ++;

            if(this.activeIndex < 3)
                this.playAnimation();
        }, 2000);
    }

}


import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import PersonInfo from '@/components/personInfo.vue';
import * as OM from "@/Model";

@Options({
    components: {
        PersonInfo
    }
})
export default class viewDescriptionModal extends Vue {

    @Prop({
        default: false
    }) ownDescription: boolean;

    @Prop() completeName: string;
    @Prop() birthData: string;
    @Prop() meters: number;
    @Prop() sexualOrientation: string;
    @Prop() description: string;

    @Prop() reload: () => void;

    openedDescription: string = "";
    localDescription: string = "";

    created(){
        this.openedDescription = JSON.parse(JSON.stringify(this.description));
        this.localDescription = JSON.parse(JSON.stringify(this.description));
    }

    close(){

        if(this.localDescription == this.openedDescription){
            this.$opModal.closeLast();
            return;
        }

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UscendoPerderaiTutteLeModificheVuoiContinuare?", "Uscendo perderai tutte le modifiche. <br />Vuoi uscire?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeAll();
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    save(){
        if(this.openedDescription == this.localDescription){
            return;
        }

        AppUserClient.updateProfileDescription(this.localDescription)
        .then( x => {
            this.reload();
            this.openedDescription = JSON.parse(JSON.stringify(this.localDescription));
        })
    }
    
}

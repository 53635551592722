
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import SpinnerContainer from '@/components/standard/spinnerContainer.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import { AdminClient, AppUserClient, LocalizationClient, LocalResourceContryClient } from '@/services/Services';
import store from '@/store';
import { LoginServices } from '@/services/LoginServices';
import * as OM from '@/Model';

@Options({
    components: {
        SpinnerContainer,
    }
})
export default class Splash extends Vue {

    @Prop() modelValue: boolean;

    configLoaded: boolean = false;
    animationFinished: boolean = false;
    componentShow: boolean = true;

    created() {
        this.loadConfig();
    }

    mounted() {      
        setTimeout(() => {
            this.animationFinished = true;
        }, 3000);
    }

    loadConfig(firstTime: boolean = true){
        Promise.all([
            LocalizationClient.getAppDictionary(!firstTime),
            LocalizationClient.getEnabledLanguages(!firstTime),
            LocalResourceContryClient.getExtendedCountries(!firstTime),
            AppUserClient.getAppConfiguration(!firstTime),
        ])
        .then( x => {
            store.state.consts.localizedValues = x[0];
            store.state.consts.enabledLanguages = x[1];
            store.state.consts.extendedCountries = x[2];

            var config = x[3];
            store.state.consts.config = config;

            let root = document.documentElement;
            root.style.setProperty('--twentyFiveBlur', config.blur.twentyFive.toString() +  "px");
            root.style.setProperty('--fiftyBlur', config.blur.fifty.toString() +  "px");
            root.style.setProperty('--seventyFiveBlur', config.blur.seventyFive.toString() +  "px");
            root.style.setProperty('--oneHundredBlur', config.blur.oneHundred.toString() +  "px");  

            store.commit("updatedLanguage");
            this.configLoaded = true;
        }).catch( ex => {

            var denyCb = null;
            if(window.cordova){
                denyCb = () => {
                    this.$opModal.closeLast();

                    if(window.cordova)
                        (<any>window.navigator).app.exitApp();
                }
            }

            var message = this.$localizationService.getLocalizedValue("app_QualcosaEAndatoStortoDuranteIlCaricamento", "Qualcosa è andato storto durante il caricamento!");

            if(ex.Message)
                message = ex.Message;

            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: message,
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                deny: this.$localizationService.getLocalizedValue("app_Esci", "Esci"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.loadConfig(false);
                },
                denyCb: denyCb
            })
        })
    }

    @Watch("configLoaded")
    @Watch("animationFinished")
    @Watch("$store.state.deviceReady")
    async canShowFunction(){
        if(!this.configLoaded || !this.animationFinished)
            return;

        if(window.cordova && !store.state.deviceReady)
            return;

        LoginServices.LoginCallback(store.state.loggedUser.token, store.state.loggedUser.refreshToken)
        .then( () => {})
        .catch( () => {})
        .finally( () => { 
            this.componentShow = false;
            setTimeout(() => {
                this.$emit("update:modelValue", true);
            }, 2200);
        });
    }
}

import { NotificationClient } from '@/services/Services';
import { RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';
import store from '@/store';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'loggeduser layout',
            component: () => import("./views/loggeduser_layout.vue"),
            beforeEnter: (to, from) => {
                if(!store.state.loggedUser.identifier)
                    return false
            },
            children: [
                {
                    path: '/premium/',
                    name: 'premium',
                    component: () => import("./views/premium.vue"),
                },
                {
                    path: '/experience',
                    name: 'experience',
                    component: () => import("./views/experience.vue"),
                },
                {
                    path: '/experience/faq',
                    name: 'experience faq',
                    component: () => import("./views/experienceFaq.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/experience/edit/:id?',
                    name: 'experience edit',
                    component: () => import("./views/experienceEdit.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/experience/detail/:id?',
                    name: 'experience detail',
                    component: () => import("./views/myExperienceDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/my-experience-list/',
                    name: 'my experience list',
                    component: () => import("./views/myExperience.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/experience-list/:creatorIdentifier',
                    name: 'experience list',
                    component: () => import("./views/experienceListByCreator.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/experience-affinity/:experienceIdentifier',
                    name: 'experience affinity detail',
                    component: () => import("./views/experienceAffinityDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/new-experience-match/:affinityIdentifier/:experienceIdentifier',
                    name: 'new experience match',
                    component: () => import("./views/newExperienceMatch.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/my-application-list/',
                    name: 'my application list',
                    component: () => import("./views/myApplications.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/application-detail/:affinityIdentifier',
                    name: 'application detail',
                    component: () => import("./views/experienceApplicationDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/finder',
                    name: 'finder',
                    component: () => import("./views/finder.vue"),
                },
                {
                    path: '/new-match/:matchIdentifier/:chatRoomIdentifier?',
                    name: 'new match',
                    component: () => import("./views/newMatch.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/affinity',
                    name: 'affinity',
                    component: () => import("./views/affinity.vue"),
                },
                {
                    path: '/chats/:identifier',
                    name: 'chat detail',
                    component: () => import("./views/chatDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/match/:matchIdentifier',
                    name: 'match detail',
                    component: () => import("./views/matchDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import("./views/profile.vue"),
                },
                {
                    path: '/profile/:identifier',
                    name: 'profile detail',
                    component: () => import("./views/profileDetail.vue"),
                    meta: { hidefooter: true }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () => import("./views/notifications.vue"),
                    meta: { hidefooter: true }
                },
            ]
        },
    ];
};
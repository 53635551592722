import { createApp } from 'vue';
import { LocalizationServices } from './services/LocalizationServices';
import { UrlServices } from './services/UrlServices';
import App from './App.vue';
import router from './router';
import store from './store';
import modalPlugin from './plugins/modalPlugin';
import MultiRangeSlider from 'multi-range-slider-vue/MultiRangeSlider.vue';
import FTS from '@/filters';
import * as DIR from '@/directives';
import 'swiper/swiper.min.css';
import "swiper/modules/autoplay/autoplay.min.css";

import './utils';
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/style.css';
import "@/assets/css/font.css";
import "@/assets/css/animation.css";
import "@/assets/css/transition.css";
import "@/assets/css/hamburger.css";
import "@/assets/css/sliderRange.css";
import 'bootstrap/js/src/collapse.js';
import { debugAnalytics, setAppVersionNumber, smartlookAPIKey } from './config';
import { initFirebase, trackPageView } from './services/Firebase/FirebaseServices';
import './registerServiceWorker';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(modalPlugin)
    .component("MultiRangeSlider", MultiRangeSlider)
    .directive('date', DIR.date)
    .directive("localizedPlaceholder", DIR.localizedPlaceholderDirective)
    .directive('longpress', DIR.longpress);

app.config.globalProperties.$filters = FTS;
app.config.globalProperties.$localizationService = LocalizationServices;
app.config.globalProperties.$urlServices = UrlServices;

if(!debugAnalytics)
    initFirebase();
else
    console.error("Piattaforma non settata! Se è un debug non è un problema, se no uno forse c'è")

router.beforeEach((to, from, next) => {        
    trackPageView(to);

    next();
})

//questo aggiunge automaticamente ogni componente nella cartella 'components'
const ComponentContext = require.context('./components', true, /\.vue$/i);
ComponentContext.keys().forEach(fileName => {
    var component = ComponentContext(fileName);
    let componentName = fileName.split('/').pop().split('.')[0];
    app.component(componentName, (<any>component).default || component);
})

document.addEventListener("deviceready", () => {
    window.open = window.cordova.InAppBrowser.open;
    
    registerSmartLook();

    FirebasePlugin.setAnalyticsCollectionEnabled(true);
    
    store.state.deviceReady = true;

    window.cordova.getAppVersion.getVersionNumber()
    .then(function (version) {
        setAppVersionNumber(version);
    });

    // document.addEventListener('chcp_updateIsReadyToInstall', logga, false);
    // document.addEventListener('chcp_updateLoadFailed', logga, false);
    // document.addEventListener('chcp_nothingToUpdate', logga, false);
    // document.addEventListener('chcp_beforeInstall', logga, false);
    // document.addEventListener('chcp_updateInstalled', logga, false);
    // document.addEventListener('chcp_updateInstallFailed', logga, false);
    // document.addEventListener('chcp_nothingToInstall', logga, false);
    // document.addEventListener('chcp_beforeAssetsInstalledOnExternalStorage', logga, false);
    // document.addEventListener('chcp_assetsInstallationError', logga, false);
});

// function logga(ev){
//     console.log(ev)
// }

function registerSmartLook(){
    if(!smartlookAPIKey.trim())
        return;

    registerCordovaSmartLook();
}
    
function registerCordovaSmartLook(){    
    Smartlook.setupAndStartRecording({smartlookAPIKey: smartlookAPIKey});
}

app.mount('#app');
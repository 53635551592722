
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from "@/Model";
import * as VM from "@/viewModel";
import GoogleAutocompleteGetList from '../input/googleAutocompleteGetList.vue';
import { CommonServices } from '@/services/CommonServices';
import store from '@/store';
import { AppUserClient } from '@/services/Services';

@Options({
    components: {
        GoogleAutocompleteGetList
    }
})
export default class googleAutocompleteGetListModal extends Vue {

    @Prop() callback: (address: VM.GoogleFilterAddressVM) => void;
    @Prop() completeAddress: string;

    googleTypes: string[] = [];
    resultList: VM.GoogleAutocompletePredictionResponseVM[] = [];
    geocoder: any;

    localCompleteAddress: string = "";
    myPositionText: string = "";

    created() {
        this.googleTypes = [
            "locality", "street_address"
        ];
        this.myPositionText = this.$localizationService.getMyPositionText();
        this.geocoder = new google.maps.Geocoder();
        this.localCompleteAddress = this.completeAddress;
    }

    timeoutHandler;
    emitText(text: string){
        clearTimeout(this.timeoutHandler);
        this.localCompleteAddress = text;
    }

    changeAddress(data: VM.GoogleAutocompletePredictionResponseVM[]){
        this.resultList = data;
    }

    async getCurrentPosition(){
        CommonServices.showSpinner();
        
        await this.$localizationService.getCurrentLocation();

        CommonServices.hideSpinner();

        var googleFilterAddressVM : VM.GoogleFilterAddressVM = {
            addressFixed: false,
            changed: true,
            latitude: store.state.currentPosition.latitude,
            longitude: store.state.currentPosition.longitude,
            description: "",
            placeId: ""
        };
        
        this.callback(googleFilterAddressVM);
    }

    //Questo si usa se si vuole usare il placeid
    async selectPlace(item: VM.GoogleAutocompletePredictionResponseVM){
        var googleFilterAddressVM : VM.GoogleFilterAddressVM = {
            addressFixed: true,
            changed: true,
            description: item.description,
            placeId: item.place_id,
            latitude: 0,
            longitude: 0
        };

        this.callback(googleFilterAddressVM);
    }

    //Questo si usa se si vuole usare le coordinate
    // selectPlace(item: any){
    //     var request = {
    //         placeId: item.place_id
    //     }
    //     this.geocoder.geocode(request, this.setLatitudeLognitude);
    // }


    // async setLatitudeLognitude(data, text) {
    //     CommonServices.showSpinner();
    //     var latitude = data[0].geometry.location.lat();
    //     var longitude = data[0].geometry.location.lng();
    //     var address = await AppUserClient.getAddressFromCoords(latitude, longitude);
    //     CommonServices.hideSpinner();

    //     this.callback(address, true);
    // }
}

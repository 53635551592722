
import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class ReferralCodeModal extends Vue {

    @Prop() referralCode: string;

    created() {
    }

    async copy(){
        // Get the text field
        var copyText = this.$refs.referralCode as any;

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        var value = copyText.value;
        // Copy the text inside the text field

        if(!window.cordova)
            await navigator.clipboard.writeText(value);
        else 
            window.cordova.plugins.clipboard.copy(value);

        // Alert the copied text
        var text = LocalizationServices.getLocalizedValue("app_IlCodice{{code}}EStatoCopiatoNegliAppunti", "Il codice {{code}} è stato copiato negli appunti", "", "", { code: value})
        store.state.timedMessage = text;
    }

}

import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export default (): Array<RouteRecordRaw> => {
    return [
        {
            path: '/',
            name: 'splash',
            redirect: "/selectlanguage",
            component: { render: () => h(RouterView) },
            meta: { bgBlue: true }
        },
        {
            path: '/selectlanguage',
            name: 'select language',
            component: () => import("./views/selectlanguage.vue"),
            meta: { bgBlue: true }
        },
        {
            path: '/shareposition',
            name: 'share position',
            component: () => import("./views/shareposition.vue"),
            meta: { bgBlue: true }
        },
        // {
        //     path: '/rules',
        //     name: 'rules',
        //     component: () => import("./views/rules.vue"),
        //     meta: { bgBlue: true }
        // },
        // {
        //     path: '/appdescription',
        //     name: 'appdescription',
        //     component: () => import("./views/appdescription.vue"),
        //     meta: { bgBlue: true }
        // },
        {
            path: '/registerphone',
            name: 'registerphone',
            component: () => import("./views/registerphone.vue"),
            meta: { bgBlue: true }
        },
        {
            path: '/insertcode',
            name: 'insertcode',
            component: () => import("./views/insertcode.vue"),
            meta: { bgBlue: true }
        },
        {
            path: '/insertemail',
            name: 'insertemail',
            component: () => import("./views/insertEmail.vue"),
            meta: { bgBlue: true }
        },
        {
            path: '/completeonboarding',
            name: 'completeonboarding',
            component: () => import("./views/completeonboarding.vue"),
            meta: { bgBlue: true }
        },

        // login
        {
            path: '/login',
            name: 'login',
            component: () => import("./views/login.vue"),
            meta: { bgBlue: true }
        },
        //delete account
        {
            path: '/deleteaccount',
            name: 'deleteaccount',
            component: () => import("./views/deleteAccount.vue"),
            meta: { bgBlue: true }
        },

    ];
};